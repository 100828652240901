const select = '<svg viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg"><path d="M16.8984 2.19533L9.77339 8.90315C9.55777 9.1469 9.27652 9.25002 8.99995 9.25002C8.72339 9.25002 8.44308 9.14746 8.22651 8.94243L1.10152 2.19533C0.650109 1.76408 0.631359 1.05158 1.05745 0.601585C1.48542 0.146897 2.20027 0.132835 2.64839 0.559398L8.99995 6.57346L15.3515 0.55471C15.7997 0.128429 16.5117 0.144554 16.9425 0.598655C17.3671 1.05158 17.3484 1.76408 16.8984 2.19533Z" fill="currentColor"/></svg>';

const checkbox = '<svg  viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg"><path d="M21.1297 0.828115C21.6078 1.26874 21.6078 1.98124 21.1297 2.37968L8.75469 14.7547C8.35625 15.2328 7.64375 15.2328 7.20312 14.7547L0.829484 8.37968C0.390172 7.98124 0.390172 7.26874 0.829484 6.82812C1.26875 6.39218 1.98125 6.39218 2.42047 6.82812L8 12.4109L19.5781 0.828115C20.0187 0.390303 20.7313 0.390303 21.1297 0.828115Z" fill="currentColor"/></svg>';

const eyeClosed = '<svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg"><path d="M5.65143 3.87908C7.31268 2.58533 9.44268 1.6002 12.0002 1.6002C15.0302 1.6002 17.4564 2.9817 19.2227 4.6227C20.9777 6.2502 22.1514 8.16645 22.7064 9.53895C22.8302 9.8352 22.8302 10.1652 22.7064 10.4615C22.2039 11.6727 21.1952 13.379 19.7102 14.9015L23.6552 17.9915C24.0452 18.299 24.1164 18.8652 23.8089 19.2552C23.5014 19.6452 22.9352 19.7165 22.5452 19.409L0.345026 2.00858C-0.0461736 1.70183 -0.114761 1.13633 0.191876 0.745051C0.498551 0.353851 1.06405 0.285263 1.45555 0.591901L5.65143 3.87908ZM7.11768 5.03145L8.84268 6.38145C9.68643 5.64645 10.7927 5.2002 12.0002 5.2002C14.6514 5.2002 16.8002 7.34895 16.8002 10.0002C16.8002 10.7952 16.6089 11.5415 16.2677 12.2015L18.2852 13.784C19.5414 12.5052 20.4714 11.0165 20.9477 10.0002C20.4039 8.84145 19.4402 7.28145 17.9964 5.93895C16.4552 4.5102 14.4452 3.36645 12.0002 3.36645C10.1064 3.36645 8.44143 4.06508 7.11768 5.03145ZM14.8089 11.0577C14.9327 10.7277 15.0002 10.3715 15.0002 9.96645C15.0002 8.3427 13.6577 6.96645 12.0002 6.96645C11.9739 6.96645 11.9514 7.0002 11.8914 7.0002C11.9739 7.19145 12.0002 7.39395 12.0002 7.56645C12.0002 7.9827 11.9102 8.3427 11.7527 8.66145L14.8089 11.0577ZM15.1614 15.944L16.7327 17.1815C15.3714 17.9165 13.7927 18.4002 12.0002 18.4002C8.97018 18.4002 6.54393 17.0202 4.77768 15.3777C3.02343 13.7165 1.85043 11.8002 1.29243 10.4615C1.16943 10.1652 1.16943 9.8352 1.29243 9.53895C1.65018 8.6802 2.26105 7.5702 3.11605 6.45645L4.53018 7.5702C3.82893 8.44395 3.36618 9.3102 3.05455 9.96645C3.56343 11.1252 4.56018 12.719 6.00393 14.0615C7.54518 15.4902 9.55518 16.6002 12.0002 16.6002C13.1514 16.6002 14.2052 16.3527 15.1614 15.944ZM7.20018 9.96645C7.20018 9.89145 7.20393 9.78645 7.21143 9.68145L9.31518 11.339C9.70893 12.1302 10.4439 12.7227 11.3252 12.8915L13.4327 14.5827C12.9789 14.6915 12.4989 14.8002 11.9664 14.8002C9.34893 14.8002 7.16643 12.6515 7.16643 9.96645H7.20018Z" fill="currentColor"/></svg>';

const eyeOpen = '<svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg"><path d="M6.66667 9.99996C6.66667 7.05413 9.05417 4.66663 12 4.66663C14.9458 4.66663 17.3333 7.05413 17.3333 9.99996C17.3333 12.9458 14.9458 15.3333 12 15.3333C9.05417 15.3333 6.66667 12.9458 6.66667 9.99996ZM12 13.3333C13.8417 13.3333 15.3333 11.8416 15.3333 9.99996C15.3333 8.15829 13.8417 6.66663 12 6.66663C11.9708 6.66663 11.9458 6.66663 11.8792 6.66663C11.9708 6.87913 12 7.10413 12 7.33329C12 8.80413 10.8042 9.99996 9.33334 9.99996C9.10417 9.99996 8.87917 9.97079 8.66667 9.87913C8.66667 9.94579 8.66667 9.97079 8.66667 9.96246C8.66667 11.8416 10.1583 13.3333 12 13.3333ZM3.97584 4.02496C5.93751 2.20163 8.63334 0.666626 12 0.666626C15.3667 0.666626 18.0625 2.20163 20.025 4.02496C21.975 5.83329 23.2792 7.96246 23.8958 9.48746C24.0333 9.81663 24.0333 10.1833 23.8958 10.5125C23.2792 12 21.975 14.1291 20.025 15.975C18.0625 17.8 15.3667 19.3333 12 19.3333C8.63334 19.3333 5.93751 17.8 3.97584 15.975C2.02584 14.1291 0.722505 12 0.102547 10.5125C-0.0341823 10.1833 -0.0341823 9.81663 0.102547 9.48746C0.722505 7.96246 2.02584 5.83329 3.97584 4.02496ZM12 2.66663C9.28334 2.66663 7.05001 3.89996 5.33751 5.48746C3.73334 6.97913 2.62584 8.71246 2.06001 9.99996C2.62584 11.25 3.73334 13.0208 5.33751 14.5125C7.05001 16.1 9.28334 17.3333 12 17.3333C14.7167 17.3333 16.95 16.1 18.6625 14.5125C20.2667 13.0208 21.3375 11.25 21.9417 9.99996C21.3375 8.71246 20.2667 6.97913 18.6625 5.48746C16.95 3.89996 14.7167 2.66663 12 2.66663Z" fill="currentColor"/></svg>';

const search = '<svg viewBox="0 0 16 16"  xmlns="http://www.w3.org/2000/svg"><path d="M15.7529 14.7188L11.5654 10.5312C12.4716 9.42188 12.9716 8.025 12.9716 6.5C12.9716 2.90937 10.0613 0 6.47164 0C2.88195 0 -0.000244141 2.91031 -0.000244141 6.5C-0.000244141 10.0897 2.90976 13 6.47164 13C7.99633 13 9.39508 12.4716 10.5029 11.5925L14.6904 15.78C14.8654 15.9281 15.0591 16 15.2498 16C15.4404 16 15.6335 15.9268 15.7801 15.7803C16.0716 15.4875 16.0716 15.0125 15.7529 14.7188ZM1.49976 6.5C1.49976 3.74312 3.74289 1.5 6.49976 1.5C9.25664 1.5 11.4998 3.74312 11.4998 6.5C11.4998 9.25688 9.25664 11.5 6.49976 11.5C3.74289 11.5 1.49976 9.25625 1.49976 6.5Z" fill="currentColor"/></svg>';

export const formKitIcons = {
  checkboxDecorator: checkbox,
  radioDecorator: checkbox,
  select: select,
  eyeClosed,
  eyeOpen,
  searchIcon: search,
};
