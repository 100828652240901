import {da, de, en, es, fi, fr, it, nl, pl, sv} from '@formkit/i18n';
import {createInput} from '@formkit/vue';
import {createConfig} from '@formkit/core';
import {formKitIcons} from '~/utils/icon';
import {generateClasses} from '@formkit/themes';
import {addAsteriskPlugin} from '~/utils/formkit/asterisk-plugin';
import {FormkitCaptcha} from '#components';

export default createConfig({
  plugins: [addAsteriskPlugin],
  locales: {de, en, fr, nl,  fi, da, sv, it, es, pl},
  locale: 'nl',
  icons: {
    ...formKitIcons,
  },
  inputs: {
    captcha: createInput(FormkitCaptcha),
  },
  config: {
    classes: generateClasses({
      global: {
        fieldset: 'max-w-md',
        help: 'text-sm font-medium',
        inner: 'border border-grey-400 hover:border-grey transition  focus-within:!border-primary rounded-lg group-disabled/formkit:bg-gray-400 group-disabled/formkit:cursor-not-allowed group-disabled/formkit:pointer-events-none overflow-clip',
        input: 'bg-white focus:outline-none focus:ring-0 focus:shadow-none placeholder:text-grey-600',
        label: 'block mb-2 font-medium text-lg group-invalid/formkit:text-red-800 group-focus-within/formkit:text-primary text-grey',
        legend: 'font-bold text-2xl',
        loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
        message: 'text-red-500 mb-1 text-sm font-medium',
        messages: 'list-none p-0 mt-1 mb-0',
        outer: 'mb-6 group-disabled/formkit:opacity-50 group/formkit group-invalid/formkit:bg-red-800',
        prefixIcon: 'pl-4 pr-0 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl bg-white [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      'family:box': {
        decorator: 'block relative h-8 w-8 mr-2 rounded bg-white border border-grey-400 text-transparent peer-checked:text-primary peer-focus-within:border-grey group-hover:border-grey transition ',
        decoratorIcon: 'flex p-2 w-full h-full',
        help: 'mb-2 mt-1.5',
        input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
        inner: '$reset',
        label: '$reset text-base text-gray-900 mt-0.5 font-satoshi select-none',
        wrapper: 'flex items-center mb-2 group cursor-pointer',
        legend: '$reset text-lg font-lato mb-2 font-medium text-grey',
      },
      'family:button': {
        input: '$reset inline-flex items-center bg-primary text-white text-lg font-satoshi font-medium px-4 py-3.5 rounded focus-visible:outline-2 focus-visible:outline-blue-600 focus-visible:outline-offset-2 formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
        wrapper: 'mb-1',
        prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
        suffixIcon: '$reset block w-4 ml-2 stretch',
      },
      'family:text': {
        inner: 'flex items-center rounded mb-1',
        input: 'w-full p-4 border-none text-base text-gray-900 placeholder-gray-700',
      },
      'family:date': {
        inner: 'flex items-center ring-1 ring-grey-400 focus-within:ring-primary focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1',
        input: 'w-full p-4 border-none text-grey-900 ',
      },
      captcha: {
        inner: 'border-none',
      },
      color: {
        inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
        input: '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
        suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
      },
      file: {
        fileItem: 'flex items-center text-grey-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0',
        fileList: 'shrink grow peer p-4 formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-base w-3 relative z-[2]',
        inner: 'bg-white relative cursor-pointer formkit-multiple:[&>button]:absolute',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        noFiles: 'flex w-full items-center p-4 text-grey-600',
        noFilesIcon: 'w-4 mr-2',
      },
      radio: {
        decorator: 'rounded-full',
        decoratorIcon: 'w-5 p-2',
      },
      range: {
        inner: '$reset flex items-center max-w-md',
        input: '$reset w-full mb-1 h-2 p-0 rounded-full',
        prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      select: {
        inner: 'flex relative items-center rounded mb-1 border-grey-400',
        input: 'appearance-none w-full pl-4 pr-8 py-4 text-base text-gray-900 formkit-multiple:p-0 formkit-multiple:data-[placeholder="true"]:text-inherit border-r-8 border-white',
        selectIcon: 'flex shrink-0 w-4 mr-4 -ml-8 h-full pointer-events-none',
        option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
      },
      textarea: {
        inner: 'flex rounded [&>label:first-child]:focus-within:text-blue-500',
        input: 'block w-full h-32 px-3 py-3 border-none text-base text-grey-900  focus:shadow-outline',
      },
    }),
  },
});
