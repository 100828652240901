<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <Notifications
    position="bottom center"
    class="mb-5"
    width="100%"
  >
    <template #body="{item, close}">
      <div class="container">
        <div
          class="mx-auto mb-2.5 w-fit max-w-xl rounded-md px-4 py-2 text-center text-white"
          :class="{
            'bg-primary': item.type !== 'error',
            'bg-salmon': item.type === 'error',
          }"
          @click="close()"
        >
          <p class="m-0 text-sm">
            {{ item.title }}
          </p>
          <div v-text="item.text" />
        </div>
      </div>
    </template>
  </Notifications>
</template>

<script setup lang="ts">
const {t} = useI18n();

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${t('website_title')}` : t('website_title');
  },
});
</script>

<style>
@media print {
  html {
    font-size: 10px;
  }
}
</style>
