import cryptoRandomString from 'crypto-random-string';
import {
  ConsolidateUserFavoritesOnAuthenticatedDocument,
  ConsolidateUserFavoritesOnAuthenticatedMutation,
} from '~/graphql/graphql';

export default defineNuxtPlugin((nuxtApp) => {
  const session = useCookie('365.session', {
    maxAge: 30 * 24 * 3600,
    secure: true,
  });

  nuxtApp.hook('apollo:headers', (headers) => {
    headers.value = {
      ...headers.value,
      'X-Session-ID': session.value,
    };
  });

  const {status} = useAuth();
  const {client} = useApolloClient();

  watchEffect(async () => {
    if (status.value === 'authenticated' && session.value) {

      const {data} = await client.mutate<ConsolidateUserFavoritesOnAuthenticatedMutation>({
        mutation: ConsolidateUserFavoritesOnAuthenticatedDocument,
      });

      session.value = null;

      if (data?.consolidateUserFavoritesOnAuthenticated) {
        // Evict collections caches to refetch any consolidated lists
        Object.keys(client.cache.data.data.ROOT_QUERY).forEach((key) => {
          if (key.match(/^collections?($|\()/)) {
            client.cache.evict({id: 'ROOT_QUERY', fieldName: key});
          }
        });
      }
    } else if (status.value === 'unauthenticated' && !session.value) {
      session.value = cryptoRandomString({length: 100, type: 'base64'});
    }
  });
});
