import {createGtm} from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (!config.public.gtmId) {
    return;
  }

  nuxtApp.vueApp.use(createGtm({
    id: config.public.gtmId,
    vueRouter: useRouter(),
    enabled: true,
  }));
});
