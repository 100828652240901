<template>
  <div
    ref="captchaContainer"
    class="frc-captcha my-4"
    :data-sitekey="publicConfig.friendlyCaptchaSiteKey"
  />
</template>

<script setup lang="ts">
import {WidgetInstance} from 'friendly-challenge';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const {public: publicConfig} = useRuntimeConfig();

const captchaContainer = ref();
const widget = ref();

const {locale} = useI18n();

function doneCallback(solution) {
  props.context.node.input(solution);
}

function errorCallback(err) {
  console.log('There was an error when trying to solve the Captcha.');
  console.error(err);
}

function initializeCaptcha() {
  widget.value = new WidgetInstance(captchaContainer.value, {
    startMode: 'auto',
    language: locale.value,
    doneCallback,
    errorCallback,
  });
}

onMounted(() => {
  if (captchaContainer.value) {
    initializeCaptcha();
  }
});
</script>
