<template>
  <NuxtLayout name="default">
    <div
      class="relative h-full rounded-4xl bg-primary-400 py-24 md:py-36"
      :class="$route.fullPath !== '/' ? 'mb-[-7.5rem]' : ''"
    >
      <img
        src="@/assets/images/shape-365-1-4.svg"
        class="pointer-events-none absolute right-0 top-0 max-md:w-80 max-sm:w-60"
        alt=""
      >
      <img
        src="@/assets/images/shape-365-1-4.svg"
        class="pointer-events-none absolute bottom-0 left-0 rotate-180 max-md:w-80 max-sm:w-60"
        alt=""
      >
      <div class="container relative text-center">
        <div class="mx-auto max-w-xl space-y-10">
          <div>
            <h1 class="mb-2 text-2xl font-medium">
              {{ error.message }}
            </h1>
            <p v-if="error.statusCode === 404">
              {{ $t('error_page.404.text') }}
            </p>
          </div>
          <UiNumbers
            :numbers="error.statusCode"
            size="big"
          />
          <UiButton
            :to="{name: 'index'}"
            :label="$t('error_page.home_button')"
            class="mx-auto"
            icon="fa6-solid:arrow-right"
            @click="handleError"
          />
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
