<template>
  <div
    class="mr-4 flex"
    :class="size === 'big' ? 'gap-8' : (size === 'medium-compact' ? '' : 'gap-1')"
  >
    <div
      v-for="number in numbersArray"
      :key="number"
      class="flex w-auto items-center rounded-lg"
      :class="[colorVariations[type], sizeVariations[size]]"
    >
      <Icon
        :name="numberVariations[number]"
        :class="size === 'big' ? 'h-60 max-w-[11rem] w-full' : (size === 'medium-compact' ? 'h-14 w-full' : 'h-5 w-3')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  type?: 'primary' | 'secondary' | 'connected'
  numbers: string | number
  active?: boolean
  size?: 'big' | 'medium-compact' | 'normal' | 'small'
}>(), {
  type: 'primary',
  active: false,
  size: 'normal',
});

const colorVariations = computed(() => ({
  'primary': props.active ? 'bg-primary text-white' : 'bg-primary-400 text-primary',
  'secondary': 'bg-white text-primary',
  'connected': props.active ? 'bg-primary text-white -mr-4' : 'text-primary -mr-4',
}));

const numberVariations = {
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
};

const sizeVariations = {
  'big': 'p-0 bg-transparent',
  'medium-compact': 'p-0',
  'normal': 'p-4',
  'small': 'p-3',
};

const numbersArray = computed(() => {
  const numberString = typeof props.numbers === 'number' ? props.numbers.toString() : props.numbers;
  return numberString.split('');
});
</script>

