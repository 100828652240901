export default defineNuxtPlugin((nuxtApp) => {
  const {status} = useAuth();
  const {client} = useApolloClient();

  // Clear cache after authentication
  watch(status, (status) => {
    if (status === 'authenticated') {
      nextTick(() => client.cache.reset());
    }
  });
});
