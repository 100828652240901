const options = {
  checkInterval: 120,
  path: '/version.json',
};

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const localVersion = config.public.version;

  if (!localVersion) {
    if (process.env.NODE_ENV !== 'development') {
      console.warn(
        'App version missing, update checks will not run.',
      );
    }

    return;
  }

  let lastCheckTime = new Date();

  useRouter().afterEach(async () => {
    const interval = +new Date() - +lastCheckTime;
    if (interval >= (options.checkInterval * 1000)) {
      lastCheckTime = new Date();

      const {version} = await $fetch<{version: string}>(options.path);

      if (version !== localVersion) {
        handleNewVersion('' + version);
      }
    }
  });
});

function handleNewVersion(version: string) {
  window.location.reload();
}
